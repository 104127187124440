import React from "react";

const Index = () => {
  return (
    <div>
      <div>
        <main className="bg-height4 bg-kpo-bpo blog-list1 career-page">
          <section className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 text-center top-banner">
                <h1 className="heading-01">Terms &amp; Conditions</h1>
                <p className="p-text">
                  By accessing this web site, you are agreeing to be bound by
                  these web site Terms and Conditions of Use, all applicable
                  laws and regulations, and agree that you are responsible for
                  compliance with any applicable local laws. If you do not agree
                  with any of these terms, you are prohibited from using or
                  accessing this site. The materials contained in this web site
                  are protected by applicable copyright and trade mark law.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
      <section className="form-list-career text-WH terms15">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <h5 className="text-WH">Use License:</h5>
              </div>
              <div className="row">
                <ul>
                  <li>
                    Permission is granted to temporarily download one copy of
                    the materials (information or software) on Ditinus
                    Technology Pvt. Ltd.’s web site for personal, non-commercial
                    transitory viewing only. This is the grant of a license, not
                    a transfer of title, and under this license you may not:
                  </li>
                  <li>Modify or copy the materials.</li>
                  <li>
                    LUse the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial).
                  </li>
                  <li>
                    Attempt to decompile or reverse engineer any software
                    contained on Ditinus Technology Pvt. Ltd.'s web site.
                  </li>
                  <li>
                    Remove any copyright or other proprietary notations from the
                    materials.
                  </li>
                  <li>
                    Transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                  <li>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by Ditinus
                    Technology Pvt. Ltd. at any time. Upon terminating your
                    viewing of these materials or upon the termination of this
                    license, you must destroy any downloaded materials in your
                    possession whether in electronic or printed format.
                  </li>
                </ul>
              </div>
              <div className="row">
                <h5 className="text-WH mt20">Disclaimer:</h5>
                <p>
                  The materials on Ditinus Technology Pvt. Ltd.’s web site are
                  provided “as is”. Ditinus Technology Pvt. Ltd. makes no
                  warranties, expressed or implied, and hereby disclaims and
                  negates all other warranties, including without limitation,
                  implied warranties or conditions of merchantability, fitness
                  for a particular purpose, or non-infringement of intellectual
                  property or other violation of rights. Further, Ditinus
                  Technology Pvt. Ltd. does not warrant or make any
                  representations concerning the accuracy, likely results, or
                  reliability of the use of the materials on its Internet web
                  site or otherwise relating to such materials or on any sites
                  linked to this site.
                </p>
              </div>
              <div className="row">
                <h5 className="text-WH mt20">Limitations:</h5>
                <p>
                  In no event shall Ditinus Technology Pvt. Ltd. or its
                  suppliers be liable for any damages (including, without
                  limitation, damages for loss of data or profit, or due to
                  business interruption,) arising out of the use or inability to
                  use the materials on Ditinus Technology Pvt. Ltd.’s Internet
                  site, even if Ditinus Technology Pvt. Ltd. or a Ditinus
                  Technology Pvt. Ltd. authorized representative has been
                  notified orally or in writing of the possibility of such
                  damage. Because some jurisdictions do not allow limitations on
                  implied warranties, or limitations of liability for
                  consequential or incidental damages, these limitations may not
                  apply to you.
                </p>
              </div>
              <div className="row">
                <h5 className="text-WH mt20">Revision &amp; Errata:</h5>
                <p>
                  The materials appearing on Ditinus Technology Pvt. Ltd.’s web
                  site could include technical, typographical, or photographic
                  errors. Ditinus Technology Pvt. Ltd. does not warrant that any
                  of the materials on its web site are accurate, complete, or
                  current. Ditinus Technology Pvt. Ltd. may make changes to the
                  materials contained on its web site at any time without
                  notice. Ditinus Technology Pvt. Ltd. does not, however, make
                  any commitment to update the materials.
                </p>
              </div>
              <div className="row">
                <h5 className="text-WH mt20">Links:</h5>
                <p>
                  Ditinus Technology Pvt. Ltd. may revise these terms of use for
                  its web site at any time without notice. By using this web
                  site you are agreeing to be bound by the then current version
                  of these Terms and Conditions of Use.
                </p>
              </div>
              <div className="row">
                <h5 className="text-WH mt20">
                  Site Terms of Use Modifications:
                </h5>
                <p>
                  Any claim relating to Ditinus Technology Pvt. Ltd.’s web site
                  shall be governed by the laws of the State of Punjab without
                  regard to its conflict of law provisions. General Terms and
                  Conditions applicable to Use of a Web Site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
