import React from "react";
import { Link } from "react-router-dom";
import { caseStudies } from "./SingleCaseStudy/data";
import { labeltag } from "src/assets";

const Index = () => {
  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Our Portfolio</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      Showcasing Success: Explore Our Impactful IT Solutions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-item-section">
          <div className="container">
            <div className="menu-tabs">
              <div className="row">
                <div className="right-menu">
                  <div className="tab-panel">
                    <div
                      className="tab-pane active tab-content"
                      id="see-all"
                      role="tabpanel"
                    >
                      <div className="row g-4">
                        {caseStudies.map((item, index) => (
                          <div
                            className="col-lg-6 col-md-6 col-sm-12"
                            key={index}
                          >
                            <Link
                              to={`/case-study/${item.slug}`}
                              className="card"
                            >
                              <figure>
                                <img
                                  className="w-100"
                                  src={item.image}
                                  alt="Portfolio"
                                />
                              </figure>
                              <div className="label-tag">
                                <p>
                                  <img src={labeltag} alt="Label" />{" "}
                                  {item.category}
                                </p>
                                <h2>{item.title}</h2>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
