import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { behance, upwork, logo } from "src/assets";

const Header = () => {
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const navTabs = document.querySelector(".navigation");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (navTabs) {
        if (currentScroll > 150) {
          navTabs.classList.add(toggleClass);
        } else {
          navTabs.classList.remove(toggleClass);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseOver = () => {
    setDropdownVisible(true);
  };

  const handleMouseOut = () => {
    setDropdownVisible(false);
  };
  return (
    <div>
      <header className="d-flex align-items-center justify-content-center w-100 header flex-column navigation  ">
        <nav className="navbar navbar-expand-lg container">
          <Link
            className="web-nav-logo-tab text-decoration-none text-denim fs-4"
            to="/"
          >
            <img src={logo} alt="img" />
          </Link>
          <div className="web-nav-links w-100 align-items-center justify-content-center d-flex menu-header">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  to="/"
                >
                  Company <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 9 }}>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/about-us" ? "active" : ""
                      }`}
                      to="/about-us"
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/training" ? "active" : ""
                      }`}
                      to="/training"
                    >
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/career" ? "active" : ""
                      }`}
                      to="/career"
                    >
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/blogs" ? "active" : ""
                      }`}
                      to="/blogs"
                    >
                      Blogs
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${
                    location.pathname === "/services" ? "active" : ""
                  }`}
                  to="/services"
                >
                  Services <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 5 }}>
                  <li className="nav-item dropdown-link">
                    <Link
                      className={`nav-link ${
                        location.pathname === "" ? "active" : ""
                      }`}
                      to="/ui-ux"
                    >
                      Design <i className="bi bi-chevron-down ms-1" />
                    </Link>
                    <ul className="sub-menu1" style={{ padding: 5 }}>
                      <li className="nav-item dropdown-link">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/ui-ux" ? "active" : ""
                          }`}
                          to="/ui-ux"
                        >
                          UI/UX
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`nav-link ${
                            location.pathname === "/graphics" ? "active" : ""
                          }`}
                          to="/graphics"
                        >
                          Graphics
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/software-development"
                          ? "active"
                          : ""
                      }`}
                      to="/software-development"
                    >
                      Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        location.pathname === "/digital-marketing"
                          ? "active"
                          : ""
                      }`}
                      to="/digital-marketing"
                    >
                      Digital Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/case-studies" ? "active" : ""
                  }`}
                  to="/case-studies"
                >
                  Case Study
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/contact-us" ? "active" : ""
                  }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className=" web-nav-links  desktop-view d-flex flex-row ms-auto align-items-center">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <Link
                  className="nav-link position-relative"
                  to="/"
                  style={{
                    width: 115,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Hire Us On <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul
                  style={{
                    width: 117,
                    padding: 8,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 19,
                  }}
                >
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.upwork.com/agencies/1293525604405526528/"
                    >
                      <img src={upwork} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.behance.net/ditinus"
                    >
                      <img src={behance} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className=" web-nav-links  navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link ">
                <Link
                  className="nav-link"
                  to="/"
                  style={{
                    width: 170,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Outsource With Us <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul
                  style={{
                    width: 163,
                    padding: 6,
                    gap: 7,
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 23,
                  }}
                >
                  <li>
                    <Link className="nav-link text-white" to="/kpo-bpo">
                      KPO/BPO
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <button
            onClick={toggleMenu}
            className="web-nav-toggle border-0 p-0 text-white toggleMenuBar "
          >
            <i className="bi bi-list display-3 d-flex align-items-center " />
          </button>
        </nav>
      </header>

      <div className={`bg-shadow-cover ${isMenuOpen ? "showShadow" : ""} `} />
      <div
        className={`mobile-nav-sidebar navbar-toggler ${
          isMenuOpen ? "showMenu" : ""
        }`}
      >
        <div className="cover-mobile-menu">
          <div className="mobile-logo-header d-flex">
            <Link
              className="web-nav-logo text-decoration-nonepx-4 text-denim fs-4"
              to="/home"
            >
              <img src={logo} alt="img" />
            </Link>
            <button
              type="button"
              onClick={toggleMenu}
              className="text-hover-gold ms-auto toggleBtnClose bg-transparent border-0"
            >
              <i className="bi bi-x-lg text-white fs-1" />
            </button>
          </div>
          <div className="mobile-menu-body py-0 flex-column d-flex">
            <ul className="navbar-nav mb-2 mb-lg-0 mobileNavigation">
              <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center text-white w-100">
                <li className="nav-item dropdown-link">
                  <Link className="nav-link" to="/">
                    Company <i className="bi bi-chevron-down ms-1" />
                  </Link>
                  <ul style={{ width: "300px !important" }}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/about-us"
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/training"
                      >
                        Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/career"
                      >
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/blogs"
                      >
                        Blogs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown-link text-white">
                  <Link className="nav-link" to="/services">
                    Services <i className="bi bi-chevron-down ms-1" />
                  </Link>
                  <ul style={{ width: "300px !important" }}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/software-development"
                      >
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/digital-marketing"
                      >
                        Digital Marketing
                      </Link>
                    </li>

                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/ui-ux"
                      >
                        UI/UX
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/graphics"
                      >
                        Graphics
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="nav-item text-white"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <Link className="nav-link" to="#">
                    Hire Us On <i className="bi bi-chevron-down ms-1" />
                  </Link>

                  {isDropdownVisible && (
                    <div className="d-flex dropdown-menu">
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.upwork.com/agencies/1293525604405526528/"
                        >
                          <img
                            src={upwork}
                            alt="Upwork logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.behance.net/ditinus"
                        >
                          <img
                            src={behance}
                            alt="Behance logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                    </div>
                  )}
                </li>
                <li className="nav-item dropdown-link text-white">
                  <Link className="nav-link" to="/services">
                    Outsource With Us <i className="bi bi-chevron-down ms-1" />
                  </Link>
                  <ul style={{ width: "120px" }}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/kpo-bpo"
                      >
                        KPO/BPO
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
            <div className="mobile-menu-btns d-flex flex-column  gap-3">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#gitInTouch"
                className="px-4 py-2 transition  text-nowrap rounded-pill fill-btn "
              >
                <Link
                  className="nav-link"
                  onClick={toggleMenu}
                  to="/case-studies"
                >
                  Case Study
                </Link>
              </button>
              <div onClick={toggleMenu} className="d-flex">
                <Link
                  to="/contact-us"
                  className=" transition text-center text-nowrap rounded-pill fill-btn outline-btn w-100 "
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
