import { Link } from "react-router-dom";
import { bottomline, onlinere6, ppc45, riseicon01, riseicon02, riseicon03, riseicon04, search85, SMO58, socialmedia958, socialmediaimg344 } from "src/assets";


const Index = () => {
  return (
    <div>
      <main className="bg-height4 bg-ui-ux">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Digital Marketing</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      We drive growth with data-driven digital marketing
                      strategies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#form-container"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section padding452">
          <div className="container">
            <div className="image-text-box">
              <div
                className="row g-3 matop revers-col"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                  <h2 className="heading-02 lh-sm">
                    <span
                      className="position-relative d-inline-block"
                      data-aos="fade-left"
                    >
                      Good Digital Marketing Converts
                      <img
                        src={bottomline}
                        alt="line"
                        className="position-absolute start-0 top-100 text-bottom-line w-100"
                      />
                    </span>
                  </h2>
                  <p className="matop">
                    How you market matters, no matter what the product. A strong
                    marketing strategy can set your business apart, attract
                    customers, and build lasting relationships, driving
                    long-term success.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 pading-img text-center">
                  <img
                    className="img-fluid"
                    src={socialmediaimg344}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="digital-marketing12">
          <div className="container">
            <div
              className="row g-3 matop text-center"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <h2 className="heading-02 lh-sm">
                <span className="position-relative d-inline-block">
                  Digital Marketing Services
                  <div className="middle-img">
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute top-100"
                    />
                  </div>
                </span>
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-sm-12">
                <div
                  className="bg-color-box"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <img src={search85} alt="line" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <h3>Search Engine Optimization</h3>
                      <p>
                        Drive organic traffic to your website and improve search
                        engine rankings with our results-driven SEO strategies.
                        Our company experienced team conducts in-depth keyword
                        research, optimizes your website’s content and
                        structure, and implements effective link-building
                        techniques. Stay ahead of the competition and increase
                        your online visibility with our&nbsp;SEO marketing
                        services.
                      </p>
                      {/* <a href="#" className="text-denim iconBtn mt-auto">
                        Read More... <i className="bi bi-arrow-up-short ms-1" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="bg-color-box">
                  <div className="row align-items-center revers-col-1">
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <h3>Social Media Marketing</h3>
                      <p>
                        Engage and connect with your target audience through
                        strategic&nbsp;social media marketing services.&nbsp;Our
                        experts create compelling content, manage your social
                        media profiles, and run targeted ad campaigns to
                        increase brand awareness, drive website traffic, and
                        foster customer engagement. Leverage the potential of
                        social media platforms to grow your business.
                      </p>
                      {/* <a href="#" className="text-denim iconBtn mt-auto">
                        Read More... <i className="bi bi-arrow-up-short ms-1" />
                      </a> */}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <img
                        src={socialmedia958}
                        alt="line"
                        data-aos="fade-left"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <img src={ppc45} alt="line" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <h3>PPC Advertising</h3>
                      <p>
                        Accelerate your business growth with targeted&nbsp;PPC
                        campaigns. Our specialists create highly optimized ad
                        campaigns across platforms like Google Ads and social
                        media networks, ensuring maximum visibility and driving
                        qualified leads to your website. Experience immediate
                        results and a higher return on investment with
                        our&nbsp;PPC campaign services.
                      </p>
                      {/* <a href="#" className="text-denim iconBtn mt-auto">
                        Read More... <i className="bi bi-arrow-up-short ms-1" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row align-items-center revers-col-1">
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <h3>Social Media Optimization</h3>
                      <p>
                        Enhance your brand’s social media presence with our
                        comprehensive SMO services. We optimize your social
                        media profiles, create engaging content, and implement
                        effective strategies to increase follower count, boost
                        engagement, and expand your reach. Harness the power of
                        social media to connect with your audience and build a
                        strong online presence.
                      </p>
                      {/* <a href="#" className="text-denim iconBtn mt-auto">
                        Read More... <i className="bi bi-arrow-up-short ms-1" />
                      </a> */}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <img src={SMO58} alt="line" />
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-12 text-center">
                      <img src={onlinere6} alt="line" />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12">
                      <h3>Online Reputation Management</h3>
                      <p>
                        Manage your online reputation effectively with
                        our&nbsp;ORM system services.&nbsp;Our experts monitor
                        your brand’s online presence, address negative feedback,
                        and promote positive content to shape a favourable
                        reputation. By managing online reviews, mitigating
                        crises, and building trust, we help safeguard your
                        brand’s image and ensure a positive online perception.
                      </p>
                      {/* <a href="#" className="text-denim iconBtn mt-auto">
                        Read More... <i className="bi bi-arrow-up-short ms-1" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center mt-4">
                <Link
                  to="/services"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Our Services{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div
            className="rise-section"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="row space56">
              <h2
                className="heading-02 lh-sm"
                style={{ color: "var(--bs-denim-color)" }}
              >
                RISE ABOVE THE REST
              </h2>
              <h2 className="heading-02 lh-sm">
                Our Strategic Marketing Blueprint for Success
              </h2>
            </div>
            <div className="row width-size4">
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="row">
                  <div className="col-12 no-box2">
                    <h3>01</h3>
                    <p>Strategy Development</p>
                  </div>
                  <div className="col-12 rise-img">
                    <img src={riseicon04} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="row revers-col1">
                  <div className="col-12 no-box2">
                    <h3>02</h3>
                    <p>Implementation and Execution</p>
                  </div>
                  <div className="col-12 rise-img">
                    <img src={riseicon03} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="row">
                  <div className="col-12 no-box2">
                    <h3>03</h3>
                    <p>Data-Driven Analysis</p>
                  </div>
                  <div className="col-12 rise-img">
                    <img src={riseicon02} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="row revers-col1 remo-dotte45">
                  <div className="col-12 no-box2">
                    <h3>04</h3>
                    <p>Continuous Improvement and Optimization</p>
                  </div>
                  <div className="col-12 rise-img">
                    <img src={riseicon01} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
