import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { careerIcon1, careerIcon2, careerIcon3, careerIcon4 } from "src/assets";
import { getJob } from "src/services";
import parse from "html-react-parser";
import Recaptcha from "../../../Components/Recaptcha";
import { submitAppyForm } from "src/services";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";
const Index = () => {
  const [job, setJob] = useState<any>({});
  console.log(job, "jobjobjobjobjob");

  const params = useParams();
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const initialTags = job.tags
    ? job.tags.split(",").map((tag) => tag.trim())
    : [];
  console.log(initialTags, "initialTagsinitialTagsinitialTagsinitialTags");

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    current_ctc: "",
    position: initialTags.length > 0 ? initialTags[0] : "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    countryName: "",
    comment: "",
  });

  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState<any>({});
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log("File selected:", file);

    if (file) {
      const fileType = file.type;
      const validTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (validTypes.includes(fileType)) {
        setSelectedFile(file);
        setFileName(file.name);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Please select a valid PDF or DOC file.",
          icon: "error",
          confirmButtonText: "Cool",
        });
        e.target.value = "";
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };
  const validate = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please Verify.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone) newErrors.phone = "Contact Number is required.";
    if (!formData.current_ctc)
      newErrors.current_ctc = "Current CTC is required.";
    if (!formData.position) newErrors.position = "Position is required.";
    if (!formData.address1) newErrors.address1 = "Address Line 1 is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.zipCode) newErrors.zipCode = "Zip Code is required.";
    if (!formData.countryName)
      newErrors.countryName = "Country Name is required.";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submission initiated");

    const validationErrors = validate();
    console.log("VALIDATIONS: ", validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      console.log("Validation errors:", validationErrors);
      setErrors(validationErrors);
      return; // Exit early if there are validation errors
    }

    setLoading(true);
    setErrors({}); // Clear previous errors

    const submissionData = new FormData();
    Object.entries({ ...formData, file: selectedFile }).forEach(
      ([key, value]) => {
        if (value) submissionData.append(key, value);
      }
    );

    console.log("Submitting data:", [...submissionData]);

    try {
      const response = await submitAppyForm(submissionData);
      console.log("Response from API:", response);

      if (response) {
        console.log("Form submitted successfully");
        await Swal.fire({
          title: "Success!",
          text: "Your form has been submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Resetting form data
        resetForm();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      await Swal.fire({
        title: "Error!",
        text: "Submission failed. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });

      setErrors((prev) => ({
        ...prev,
        api: "Submission failed. Please try again.",
      }));
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      lastName: "",
      email: "",
      phone: "",
      current_ctc: "",
      position: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      countryName: "",
      comment: "",
    });
    setToken("");
    setSelectedFile(null);
    setFileName("");
  };

  const handleJob = async (id: any) => {
    try {
      const response: any = await getJob(id);
      if (response) {
        setJob(response?.data);
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  useEffect(() => {
    if (params) {
      const { id } = params;
      handleJob(id);
    }
  }, [params]);

  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo blog-list1 career-page">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 text-center top-banner">
                <h1 className="heading-01">{job?.title}</h1>
                <div className="row">
                  <div className="open-role-box text-WH">
                    <div className="name-tag-12">
                      {job?.tags
                        ?.split(",")
                        ?.map((item: any, Index: number) => (
                          <p key={Index}>
                            <span>{item}</span>
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="form-list-career text-WH">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <h5 className="text-WH">Responsibilities:</h5>
                </div>
                <div className="row">
                  <ul>{parse(job?.responsibilities ?? "")}</ul>
                </div>
                <div className="row">
                  <h5 className="text-WH">Requirements and skills :</h5>
                </div>
                <div className="row">
                  <ul>{parse(job?.requirement_skills ?? "")}</ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="form-comment">
                  <div className="row text-center mb-3">
                    <h3>Apply Now</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <span className="error">{errors.name}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <span className="error">{errors.lastName}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="number"
                          name="phone"
                          placeholder="Contact Number"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                        {errors.phone && (
                          <span className="error">{errors.phone}</span>
                        )}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="number"
                          name="current_ctc"
                          placeholder="Current CTC"
                          value={formData.current_ctc}
                          onChange={handleChange}
                        />
                        {errors.current_ctc && (
                          <span className="error">{errors.current_ctc}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <select
                          name="position"
                          value={formData.position}
                          onChange={handleChange}
                        >
                          <option value="">
                            {job.title || "Select a job"}
                          </option>
                          {initialTags.length > 0 ? (
                            initialTags.map((tag, index) => (
                              <option key={index} value={tag}>
                                {tag}
                              </option>
                            ))
                          ) : (
                            <option value="">No tags available</option>
                          )}
                        </select>
                        {errors.position && (
                          <span className="error">{errors.position}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="address1"
                          placeholder="Address Line 1"
                          value={formData.address1}
                          onChange={handleChange}
                        />
                        {errors.address1 && (
                          <span className="error">{errors.address1}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="address2"
                          placeholder="Address Line 2"
                          value={formData.address2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          value={formData.city}
                          onChange={handleChange}
                        />
                        {errors.city && (
                          <span className="error">{errors.city}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="state"
                          placeholder="State"
                          value={formData.state}
                          onChange={handleChange}
                        />
                        {errors.state && (
                          <span className="error">{errors.state}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="number"
                          name="zipCode"
                          placeholder="Zip Code"
                          value={formData.zipCode}
                          onChange={handleChange}
                        />
                        {errors.zipCode && (
                          <span className="error">{errors.zipCode}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <input
                          type="text"
                          name="countryName"
                          placeholder="Country Name"
                          value={formData.countryName}
                          onChange={handleChange}
                        />
                        {errors.countryName && (
                          <span className="error">{errors.countryName}</span>
                        )}
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex w-100">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            className="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <div className="d-flex w-100">
                            <input
                              type="text"
                              name="file-name"
                              id="file-name"
                              className="file-name"
                              readOnly
                              value={fileName}
                              placeholder="Resume"
                            />
                            <input
                              type="button"
                              className="btn"
                              value="Choose file"
                              onClick={handleButtonClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          name="comment"
                          placeholder="Additional Comments*"
                          value={formData.comment}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-12">
                        <div className="row text-center">
                          <button
                            type="submit"
                            className="fill-btn outline-btn2 border-0 text-uppercase round-btn"
                            disabled={loading}
                          >
                            {loading ? (
                              <Circles
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="hourglass-loading"
                                wrapperClass="d-flex justify-content-center"
                                color="white"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                        <div className="mt-3">
                          {errors.verify && (
                            <span className="error">{errors.verify}</span>
                          )}
                          <Recaptcha onChange={onCaptchaChange} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-core">
          <div className="container">
            <div className="row mt-2">
              <h2 className="heading-02 lh-sm text-center text-WH">
                Our Core Values
              </h2>
            </div>
            <div className="row">
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon1} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Growth Mindset</h5>
                  <p className="text-WH p-text">
                    We grow by accepting challenges that stretch us outside of
                    our comfort zone. These experiences shape us, and we come
                    out of them stronger.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon2} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">
                    Lead With Empathy
                  </h5>
                  <p className="text-WH p-text">
                    We work from a place of empathy first in everything we do.
                    It’s how we build our product, support our customers, and
                    treat each other here at Uscreen.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon3} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Dream Big</h5>
                  <p className="text-WH p-text">
                    We’ve made it this far because we dream big! We talk about
                    “big hairy audacious goals”, formulate plans to achieve
                    them, and support each other along the way.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon4} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Push The Pace</h5>
                  <p className="text-WH p-text">
                    We play to win. That means raising our standards, increasing
                    velocity, narrowing our focus and pushing the pace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
