import { useCallback, useEffect, useState } from "react";
import { submitTrainingForm } from "src/services";
import {
  bluecheckicon1,
  bottomline,
  bottomline2,
  CareerGrowth,
  careerIcon3,
  clientapproachicon,
  CustomerCentric04,
  enrollimg12,
  experienceicon,
  Expertise01,
  exploreicon1,
  GraphicDesigner,
  Hands,
  HTMLCSSBootstrap,
  imagetalk,
  Innovative03,
  InsiderKnowledge,
  JobReady,
  Outwith,
  Practicalicon,
  Proven02,
  shareicon,
  traningimg565,
  UIUX,
  upskilicon,
} from "src/assets";
import ComingSoon from "src/Components/ComingSoon";
import Recaptcha from "../../Components/Recaptcha";
import { features } from "./data";
import { Link } from "react-router-dom";
import { Circles, Hourglass } from "react-loader-spinner";
import Swal from "sweetalert2";

const Index = () => {
  const splitIndex = Math.ceil(features.length / 2);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    comment: "",
  });

  const [token, setToken] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleClick = (event) => {
      const targetLink = event.target.closest('a[href="#form-container"]');
      const link = event.target.closest('a[href="#container"]');
      if (targetLink && link) {
        event.preventDefault();
        const target = document.querySelector("#form-container");
        const target2 = document.querySelector("#container");
        if (target && target2) {
          target.scrollIntoView({ behavior: "smooth" });
          target2.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const onChange = (event) => {
    if (errors) {
      setErrors({});
    }
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please verify.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be digits only.";
    }
    if (!formData.services) newErrors.services = "Please select a service.";
    if (!formData.comment)
      newErrors.comment = "Additional comments are required.";

    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      await Swal.fire({
        title: "Error!",
        text: "Please fix the validation errors.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setLoading(true);
      setErrors({});
      try {
        const response: any = await submitTrainingForm(formData);
        if (response) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
            services: "",
            comment: "",
          });
          setToken("");
          await Swal.fire({
            title: "Success!",
            text: "Your form has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        setLoading(false);
        await Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your form. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo blog-list1 career-page">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 text-center top-banner">
                <div className="row justify-content-center align-items-center">
                  <div className="row text-center">
                    <h1 className="heading-01">Unleash Your Future</h1>
                    <p className="p-text fw-normal">
                      In-Demand Industrial Training Courses In Mohali You
                      Can't-Miss!
                    </p>
                  </div>
                  <div className="col-12 text-center d-flex justify-content-center mt-3">
                    <a
                      href="#container"
                      className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                    >
                      Explore Courses{" "}
                      <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="cout-number">
          <div className="container">
            <div className="row text-WH">
              <div className="col-lg-3 col-md-3 col-6">
                <div className="cout-nb">
                  <h2>
                    10 <span>+</span>
                  </h2>
                  <p>Years of Experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="cout-nb">
                  <h2>99%</h2>
                  <p>Accuracy Rate</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="cout-nb">
                  <h2>
                    500 <span>+</span>
                  </h2>
                  <p>Positive Reviews</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="cout-nb">
                  <h2>
                    600 <span>+</span>
                  </h2>
                  <p>Trusted Partners</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="free-demo2 mtrem1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-12 text-center">
                <h2 className="heading-02 lh-sm">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                  >
                    Why is Ditinus Technology The Best for 6 Months of
                    Industrial Training?
                    <img
                      src={bottomline2}
                      alt="line"
                      className="position-absolute top-100 object-fit-contain"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "600px",
                      }}
                    />
                  </span>
                </h2>
                <p className="text-WH mt40 font-18">
                  Listen Up! We’re not your average tutors who make you cram
                  everything. With us, learning is so much more than just
                  theory—because without fun, nothing sticks! Did you know that
                  80% of IT pros say industrial training played a key role in
                  landing their first job?
                </p>

                <p className="text-green  mt10" style={{ fontSize: "14px" }}>
                  It’s time to embrace the tech-tastic you! Wondering why we’re
                  the best for 6 months of Industrial Training? Read on!
                </p>
              </div>
            </div>
            <div className="row serviceBox-Contain">
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox2">
                  <div className="img-icon " data-aos="fade-left">
                    <img src={Outwith} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH">
                    Out with the Old, In with the New
                  </h5>
                  <p className="text-WH p-text  mt-3">
                    We empower you with the latest tech trends—no outdated stuff
                    here!
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox2">
                  <div className="img-icon " data-aos="fade-left">
                    <img src={InsiderKnowledge} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH ">
                    Insider Knowledge from the Experts
                  </h5>
                  <p className="text-WH p-text  mt-3">
                    Our industry pros mentor you with insider tips that no one
                    else will spill.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox2 ">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={Hands} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH"> Hands-on, All the Way!</h5>
                  <p className="text-WH p-text mt-3">
                    Forget boring theory—we dive deep into practical skills that
                    truly matter.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox2">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={JobReady} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH">Be Job-Ready</h5>
                  <p className="text-WH p-text  mt-3">
                    With top-notch training, we equip you with everything you
                    need to land your dream gig!{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#form-container"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Learning Now{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="free-demo2 mtrem2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-12 text-center">
                <h2 className="heading-02 lh-sm">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                  >
                    Sign Up for a Free Demo Class
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute  top-100 object-fit-contain"
                    />
                  </span>
                </h2>
                <p className="text-WH mt40 font-18">
                  At Ditinus Technology, we offer 3 and 6 month industrial
                  training courses in Mohali spanning the basic foundational and
                  advanced modules!
                </p>
              </div>
            </div>
            <div className="row serviceBox-Contain">
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={upskilicon} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH">Upskill</h5>
                  <p className="text-WH p-text">
                    and explore career opportunities in Web Design, Graphic
                    Design, and UI/UX.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={experienceicon} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH">Experience</h5>
                  <p className="text-WH p-text">
                    interactive training sessions the elevate your skills.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={exploreicon1} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH"> Explore</h5>
                  <p className="text-WH p-text">
                    our FREE DEMO SESSIONS for a sneak peek into our approach
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={shareicon} alt="icon" />
                  </div>
                  <h5 className="p-text18 text-WH">Share</h5>
                  <p className="text-WH p-text">
                    your details for a look at our process.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#form-container"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Learning Now{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="business-mail-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="heading-02 pb-3">
                  Join the adventure in our&nbsp;free demo sessions&nbsp;now!
                </h2>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-8 col-12">
                <form className="row">
                  <div className="col-12">
                    <input
                      type="email"
                      placeholder="E-mail"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="number"
                      placeholder="Phone No"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="fill-btn outline-btn2 w-100 rounded-pill"
                    >
                      Join Now
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-3">
                    <Recaptcha />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
        <section className="lets-talk1">
          <div className="container">
            <div className="row mt-4 design-text whyChooseUs-cards">
              <div
                className="col-xl-3 col-lg-3 col-md-6 design-text-box"
                data-aos="fade-up"
              >
                <div className="img-icon mx-auto rounded-pill text-center">
                  <img
                    src={clientapproachicon}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h5 className="text-WH">Networking Opportunities</h5>
                <p className="text-WH p-text">
                  Connect with professionals and peers, building valuable
                  relationships for future collaborations.
                </p>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 design-text-box"
                data-aos="fade-up"
              >
                <div className="img-icon mx-auto rounded-pill text-center">
                  <img
                    src={careerIcon3}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h5 className="text-WH">Expert Guidance</h5>
                <p className="text-WH p-text">
                  Benefit from the expertise of professionals who will guide you
                  through the subject matter, offering valuable insights and
                  advice.
                </p>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 design-text-box"
                data-aos="fade-up"
              >
                <div className="img-icon mx-auto rounded-pill text-center">
                  <img
                    src={CareerGrowth}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h5 className="text-WH">Career Growth Opportunities</h5>
                <p className="text-WH p-text">
                  We focus solely on teaching you what benefits you in the real
                  world. Get a chance to learn skills that are high in demand,
                  paving ways for exciting growth opportunities.
                </p>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 design-text-box"
                data-aos="fade-up"
              >
                <div className="img-icon mx-auto rounded-pill text-center">
                  <img
                    src={Practicalicon}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h5 className="text-WH">Practical Learning Experience</h5>
                <p className="text-WH p-text">
                  We avoid the boring textbook methods, opting for a hands-on
                  learning approach. Our industry experts guide you through
                  practical exercises, ensuring you acquire skills directly
                  applicable in the real-world industry.
                </p>
              </div>
            </div>
            <div
              id="form-container"
              className="row align-items-center form-talk12"
            >
              <div className="col-lg-6 col-md-12 co-sm-12">
                <div className="form-comment">
                  <div className="row text-center mb-3 text-WH">
                    <h3>Let’s Talk</h3>
                    <h4>Register a free demo class</h4>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="col-12">
                        {errors.name && (
                          <span className="error">{errors.name}</span>
                        )}
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12">
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12">
                        {errors.phone && (
                          <span className="error">{errors.phone}</span>
                        )}
                        <input
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                          value={formData.phone}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12">
                        {errors.services && (
                          <span className="error">{errors.services}</span>
                        )}
                        <select
                          name="services"
                          className="formInput"
                          value={formData.services}
                          onChange={onChange}
                        >
                          <option value="">Select a service</option>
                          <option value="Graphic Design">Graphic Design</option>
                          <option value="UI/UX">UI/UX</option>
                          <option value="HTML/CSS Bootstrap">
                            HTML/CSS Bootstrap
                          </option>
                        </select>
                      </div>
                      <div className="col-lg-12">
                        {errors.comment && (
                          <span className="error">{errors.comment}</span>
                        )}
                        <textarea
                          name="comment"
                          value={formData.comment}
                          onChange={onChange}
                          placeholder="Additional Comments*"
                        />
                      </div>

                      <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <button
                              type="submit"
                              className="fill-btn outline-btn2 border-0 text-uppercase round-btn"
                              disabled={loading}
                            >
                              {loading ? (
                                <Circles
                                  visible={true}
                                  height="30"
                                  width="30"
                                  ariaLabel="hourglass-loading"
                                  wrapperStyle={{}}
                                  wrapperClass="d-flex justify-content-center"
                                  color={"white"}
                                />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                            {errors.verify && (
                              <span className="error">{errors.verify}</span>
                            )}
                            <Recaptcha onChange={onCaptchaChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img src={imagetalk} className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section id="container" className="out-traning">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="heading-02 pb-3">Our Courses</h2>
                <p className="text-WH font-18">
                  Level Up and Take the Lead with These&nbsp;Interactive
                  Training Courses!
                </p>
              </div>
            </div>
            <div className="row mt20">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="round12">
                  <img
                    src={GraphicDesigner}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h4 className="text-WH">Graphic Design</h4>
                <p className="text-WH">
                  Dive into the world of graphic design with our interactive
                  training course! Unlock your creativity, learn
                  industry-leading techniques, and turn your passion into a
                  profession. Join us for a design revolution!
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="round12">
                  <img src={UIUX} alt="icon" className="my-auto mx-auto" />
                </div>
                <h4 className="text-WH">UI/UX</h4>
                <p className="text-WH">
                  Transform dreams into realities with our interactive UX/UI
                  training course. Dive into the world of design magic where
                  innovation and creativity merge. Boost your skills for a
                  better career ahead!
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <div className="round12">
                  <img
                    src={HTMLCSSBootstrap}
                    alt="icon"
                    className="my-auto mx-auto"
                  />
                </div>
                <h4 className="text-WH">HTML/CSS Bootstrap</h4>
                <p className="text-WH">
                  Share your thoughts, stories, and expertise with the world
                  through a visually appealing and user-friendly blog or
                  personal website.
                </p>
              </div>
            </div>
            <div className="row mt40">
              <div
                className="bg-color-box"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                <div className="row align-items-center revers-col g-3">
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <h2>
                      Why Choose Our 3 or 6 Months Industrial Training Courses
                      in Mohali?
                    </h2>
                    <p>
                      Because we're not your average industrial training center
                      in Mohali! Our exciting learning courses are equipped with
                      hands-on experience and industry-relevant topics necessary
                      for acing the diverse fields of graphic, web, and UI/UX
                      design. Our expert instructors have shaped the course of
                      the curriculum in a way that caters to the skill levels of
                      both beginners and seasoned pros. Beyond boring textbooks,
                      our experts guide you through a series of hands-on and
                      engaging classes where you'll put your new skills to the
                      test in real-world scenarios. Get ready for a mix of fun
                      and learning that'll have you saying ‘Wow’.
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 text-center">
                    <img
                      className="w-100"
                      src={traningimg565}
                      alt="line"
                      style={{ borderRadius: 19 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="free-demo2">
          <div className="container">
            <div className="row g-5 text-WH text-center">
              <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                <div className="why-choose45">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={Expertise01} alt="icon" />
                  </div>
                  <h5>Expertise</h5>
                  <p>
                    Benefit from our experienced professionals with wealth of
                    knowledge in industry trends.
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                <div className="why-choose45">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={Proven02} alt="icon" />
                  </div>
                  <h5>Proven Results</h5>
                  <p>
                    Our track record of success speaks volume of our expertise
                    and knowledge in the field. Transform goals into
                    achievements today!
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                <div className="why-choose45">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={Innovative03} alt="icon" />
                  </div>
                  <h5>Innovative Solutions</h5>
                  <p>
                    We do not follow the herd! Our unique methodologies and
                    solutions go far beyond just problem solving, enriching your
                    knowledge to the maximum potential.
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                <div className="why-choose45">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={CustomerCentric04} alt="icon" />
                  </div>
                  <h5>Customer-Centric Focus</h5>
                  <p>
                    Customer satisfaction is our top priority, enabling us to
                    craft intuitive solutions with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt40">
              <div className="col-12 text-center d-flex justify-content-center mt30">
                <a
                  href="#form-container"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Explore Courses{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="what-all">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9 col-12 text-center">
                <h2 className="heading-02 lh-sm">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                  >
                    What All You Can Gain With Our&nbsp;Industrial Training
                    Courses in Mohali?
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute  top-100 object-fit-contain"
                    />
                  </span>
                </h2>
                <p className="text-WH mt40 font-18">
                  Our 3 and 6 month industrial training course in Mohali offers
                  everyone a wide range of benefits and learning opportunities,
                  regardless of skill level. Here are some amazing and unique
                  things you will learn when you choose our courses in Mohali:
                </p>
              </div>
            </div>
            <div className="row whta-all-number1 mt40 text-WH">
              <div className="col-lg-6 col-md-6 col-ms-12">
                {features?.slice(0, splitIndex).map((feature) => (
                  <div className="row align-items-center" key={feature?.id}>
                    <div className="col-2">
                      <h4>{feature?.id}</h4>
                    </div>
                    <div className="col-10">
                      <p>{feature?.text}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-6 col-md-6 col-ms-12">
                {features?.slice(splitIndex).map((feature) => (
                  <div className="row align-items-center" key={feature?.id}>
                    <div className="col-2">
                      <h4>{feature?.id}</h4>
                    </div>
                    <div className="col-10">
                      <p>{feature?.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="enroll-12">
          <div className="container">
            <div className="row text-WH">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="content4">
                  <div className="row">
                    <h3>
                      Who Can Enroll in Our&nbsp;Industrial Training Courses in
                      Mohali?
                    </h3>
                    <p>
                      At our&nbsp;industrial training courses in Mohali, we
                      believe that learning knows no bounds. Whether you’re
                      fresh out of school or seasoned in&nbsp;your career, age
                      is just a number when it comes to acquiring new skills and
                      knowledge. So, regardless of your age or background, if
                      you’re ready to learn and grow, we welcome you with open
                      arms! Our courses are curated for people who are
                      passionate about making an impression on the world,
                      propelling their career success fast forward.
                    </p>
                    <br />
                    <h3>
                      Who Can Enroll in Our&nbsp;Industrial Training Courses in
                      Mohali?
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src={bluecheckicon1} />
                    </div>
                    <div className="col-10">
                      <p>
                        Students- High school, college, or pursuing further
                        education.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src={bluecheckicon1} />
                    </div>
                    <div className="col-10">
                      <p>Seasoned Professionals.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src={bluecheckicon1} />
                    </div>
                    <div className="col-10">
                      <p>Entrepreneurs</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src={bluecheckicon1} />
                    </div>
                    <div className="col-10">
                      <p>Design Creatives.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src={bluecheckicon1} />
                    </div>
                    <div className="col-10">
                      <p>Anyone interested in design and digital mastery.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="img-shadow5">
                  <img src={enrollimg12} className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-box mtrem2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9 col-12 text-center">
                <h2 className="heading-02 lh-sm"> FAQ’s </h2>
                <img src={bottomline} alt="line" />
              </div>
            </div>
            <div className="row mt40">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item rounded-3 border-0 shadow mb-2">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Why is industrial training important?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Our 3 and 6 months of industrial training in Mohali will
                        help you bridge the gap between theoretical and
                        practical knowledge gained in classrooms and real-world
                        scenarios. It helps individuals develop practical
                        skills, gain industry exposure, and offers doors to
                        bright career opportunities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item rounded-3 border-0 shadow mb-2">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Who can participate in industrial training in Mohali?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Our 3 and 6 month industrial training in Mohali is
                        designed to suit students, graduates, seasoned pros,
                        freshers, entrepreneurs, and anyone interested in design
                        and development.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What are the benefits of industrial training in Mohali for
                      students?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Our industrial training in Mohali offers several
                        benefits to students including gaining practical
                        knowledge, real-world industry trends, building
                        professional networks, and exploring a wide range of
                        lucrative career options.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Can industrial training lead to job placement?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Job placement after industrial training in Mohali
                        depends upon your skill, and efficiency during the whole
                        process. These programs may offer internships that can
                        directly transition into full-time employment upon the
                        completion of certain requirements decided by the
                        company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
