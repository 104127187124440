import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom"; // Import Link from react-router-dom
import { caseStudies } from "./data";
import {
  labeltag,
  portfoliodetails,
  qutesiocn,
  profilepic,
  ux04,
} from "src/assets";

const CaseStudys = () => {
  const { slug } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);

  console.log(
    caseStudy,
    "caseStudycaseStudycaseStudycaseStudycaseStudycaseStudycaseStudycaseStudycaseStudycaseStudy"
  );

  useEffect(() => {
    console.log("Fetched slug:", slug);
    const fetchedCaseStudy = caseStudies.find((study) => study.slug === slug);
    console.log("Fetched case study:", fetchedCaseStudy);
    setCaseStudy(fetchedCaseStudy);
  }, [slug]);

  if (!caseStudy) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1 poortfolio-single-page">
        <section className="subBanner">
          <div className="container">
            <div className="row">
              <h1 className="heading-01">{caseStudy.title}</h1>
            </div>
          </div>
        </section>
        <section className="our-portfoliotext">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <figure>
                  <img
                    className="w-100 "
                    src={caseStudy?.image2}
                    alt="Portfolio Details"
                  />
                </figure>
              </div>
            </div>
            <div className="row g-3 pt-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">{caseStudy.title}</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy.description}</p>
              </div>
              <hr style={{ color: "#CCE3FF" }} />
              {/* <div className="name-tag-12">
                <p>
                  <span>Services:</span> {caseStudy.services}
                </p>
                <p>
                  <span>Client:</span> {caseStudy.client}
                </p>
                <p>
                  <span>Location:</span> {caseStudy.location}
                </p>
                <p>
                  <span>Completed Date:</span> {caseStudy.completedDate}
                </p>
              </div> */}
            </div>
            {/* <div className="row p-ding2 align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="round-img">
                  <figure>
                    <img
                      className="img-thumbnail"
                      src={profilepic}
                      alt="Profile Pic"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-10 col-sm-12">
                    <div className="row">
                      <div className="col-12 pb-4">
                        <img src={qutesiocn} alt="Quote Icon" />
                      </div>
                      <div className="col-12 text-WH">
                        <p>
                          Excellent work on the web development project! The
                          design is clean and user-friendly, and everything
                          works smoothly across devices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">{caseStudy?.projectRequirement}</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy?.requirementDes}</p>
              </div>
            </div>

            <div className="row p-ding2 text-WH ">
              {caseStudy.requirements.map((requirement, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-1">
                      <img
                        className="img-fluid"
                        src={ux04}
                        alt="Requirement Icon"
                      />
                    </div>
                    <div className="col-11">
                      <p>{requirement}</p>
                    </div>
                  </div>
                </div>
              ))}
              {caseStudy.requirements2.map((requirement, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-1">
                      <img
                        className="img-fluid"
                        src={ux04}
                        alt="Requirement Icon"
                      />
                    </div>
                    <div className="col-11">
                      <p>{requirement}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">Solution &amp; Result</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy.solution}</p>
              </div>
            </div>
            <div className="row g-3 p-ding2">
              <div className="right-menu">
                <div className="row g-4">
                  {caseStudy?.images.map((image, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                      <Link to={`/case-study/${caseStudy?.slug}`}>
                        <div className="card">
                          <figure>
                            <img
                              className="w-100"
                              src={image}
                              alt={`Portfolio Image ${index + 1}`}
                            />
                          </figure>
                          <div className="label-tag">
                            <p>
                              <img src={labeltag} alt="Label" />{" "}
                              {caseStudy.category}
                            </p>
                            <h2>{caseStudy.title}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CaseStudys;
