import {
    DasRealEstateBanner,
    DasRealEstateThumbnail,
    FMCSABanner,
  FMCSAThumbnail,
  JuiceLadyBanner,
  JuiceLadyThumbnail,
  MundoChiapasBanner,
  MundoChiapasThumbnail,
  portfolioimage01,
  portfolioimage07,
  portfolioimage08,
  TerryChewBanner,
  TerryChewThumbnail,
  WayneHomeDesignBanner,
  WayneHomeDesignThumbnail,
} from "src/assets";

export const caseStudies = [
  {
    id: "1",
    slug: "about-fmcsa-compliance-agency-llc",
    image: FMCSABanner,
    image2: FMCSAThumbnail,
    title: "About FMCSA Compliance Agency LLC.",
    description:
      "FMCSA Compliance Agency LLC assists commercial vehicle operators in meeting FMCSA regulations, ensuring compliance with mandatory safety and regulatory requirements. ",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "Client's Requirements:",
    requirementDes:
      "The client needed a user-friendly website that would streamline the registration process and make compliance regulations easier to obtain.",
    requirements: [
      "A functional, user-friendly website",
      "Easy enrollment for various training modules",
      "Effortless enrollment sections for mandatory regulatory compliance forms",
      "Dedicated video lecture sections for advanced driver and supervisor training",
      "Secure payment integration for seamless transactions",
    ],
    requirements2: [
      "UCR registration form with real-time USDOT number verification and updates.",
      "Centralized control panel for admin and super admin dashboards",
      "Contact form for effortless lead generation",
      "FAQ section to answer common queries efficiently",
    ],
    category: "Technology",
    solution:
      "At Ditinus Technology, we developed the website from the scratch, ensuring top-tier functionality and seamless code quality. Our design focuses on user experience, allowing for easy enrollment in training modules and compliance registration forms, enabling users to sign up in just a few clicks. We incorporated secure Stripe payment integration, allowing customers to complete transactions without delays.Furthermore, our video modules for employee and supervisor training provide in-depth insights into every aspect, giving users access to learning materials with ease. We also integrated a dedicated Unified Carrier Registration (UCR) section, enabling users to check their USDOT number and verify renewal status in just a few clicks.To streamline operations, we implemented admin and super admin dashboards that facilitate efficient data management, making it easy for administrators to handle everything effortlessly.",
    images: [portfolioimage07, portfolioimage08],
  },

  {
    id: "2",
    slug: "about-das-real-estate",
    image: DasRealEstateBanner,
    image2: DasRealEstateThumbnail,
    title: "About DAS Real Estate",
    description:
      "DAS Real Estate specializes in design, repair, and renovation services for clients in Maryland, DC, and Virginia. They provide quick estimates and detailed consultations, ensuring clients receive tailored solutions for their property needs. ",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client contacted Ditinus Technology with the following requirements:",
    requirements: [
      "A responsive website that works well across different devices and screen sizes.",
      "A dedicated section for showcasing completed projects and portfolio.",
      "Links to the company’s social media handles. ",
    ],
    requirements2: [
      "A section specifically designed to detail the range of real estate services offered.",
      "An easily accessible contact form for inquiries, allowing visitors to reach out effortlessly.",
    ],
    category: "Technology",
    solution:
      "At Ditinus Technology we brought our client’s vision to life by building a highly intuitive and functional website from the scratch. Our skilled team utlizied the power of PHP to create a seamless online experience that perfectly meets their needs and objectives. We developed a responsive website that adapts seamlessly across all devices. A dedicated section showcases a detailed portfolio of past projects, building trust and credibility with potential clients. To strengthen online presence, we integrated links to social media handles, improving engagement. An easy-to-use contact form was implemented to streamline communication and increased lead generation. Additionally, a services section allows visitors to easily understand the range of offerings, ultimately leading to more inquiries and service bookings.",
    images: [portfolioimage07, portfolioimage08],
  },

  {
    id: "3",
    slug: "about-wayne-home-designs",
    image: WayneHomeDesignBanner,
    image2: WayneHomeDesignThumbnail,
    title: "About Wayne Home Designs",
    description:
      "The company specializes in providing a wide range of home furnishings at highly affordable prices. Each product is crafted with precision and care, ensuring quality and style to enhance any living space. ",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client had a great website to begin with, but lacked some major gigs to improve its overall appeal. They approached Ditinus Technology to refine the user interface and enhance the user experience, ensuring it aligned better with their vision and expectations.",
    requirements: [
      "Modern design elements along wih an updated colour palette",
      "A simplified navigation structure",
    ],
    requirements2: [
      "A fully responsive website.",
      "Improved typography, and consistent styling across the website. ",
    ],
    category: "Technology",
    solution:
      "We began the process by first carefully understanding and analyzing the requirement of our client and prototyping a structure to suit their needs. We focused on improving both the aesthetics and the functionality of the website by gathering feedback to identify areas for improvement within the existing UI.  Our team crafted mockups of the new UI, showcasing features of modern design, intuitive navigation, and an updated color palette to make things pop even more. To make the website easily navigable, we introduced several key features like, reorganizing the drop down menu and adding quick-access buttons. To make the website completely responsive, we optimized the UI for several devices and screen sizes, providing a satisfactory experience to the users. Additionally, we used improved typography, and consistent styling across the website creating a more engaging user experience and improving the overall aesthetic appeal of the site.   ",
    images: [portfolioimage07, portfolioimage08],
  },

  {
    id: "4",
    slug: "about-the-juice-lady",
    image: JuiceLadyBanner,
    image2: JuiceLadyThumbnail,
    title: "About The Juice Lady ",
    description:
      "Our client, The Juice Lady, is well-known for her organic fruit juices made from scratch. She offers a variety of juices designed for endurance, recovery, and detox.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client approached Ditinus Technology to enhance their overall online shopping experience. ",
    requirements: [
      "They required a robust cart functionality that would allow customers to easily add and delete items.",
      "A streamlined checkout process that provided a clear price breakdown for transparency. ",
    ],
    requirements2: [
      "A secure and robust payment gateway for secure and seamless transcations.",
    ],
    category: "Technology",
    solution:
      "To meet these needs, we developed an intuitive shopping cart system, enabling effortless management of product selections. Our team also designed a user-friendly checkout page showcasing the total costs, including taxes and discounts, building customer trust. Finally, we integrated a secure payment gateway, ensuring safe transactions and enhancing overall customer satisfaction for our client.",
    images: [portfolioimage07, portfolioimage08],
  },

  {
    id: "5",
    slug: "terry-chew-academy",
    image: TerryChewBanner,
    image2: TerryChewThumbnail,
    title: "About Terry Chew Academy",
    description:
      "Terry Chew Academy is dedicated to making math an enjoyable and accessible subject for students. With a focus on simplicity and effectiveness, the academy transforms math learning into a rewarding experience for all students. ",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "",
    requirements: [
      "An intuitive online learning platform for a better experience for students and parents.",
      "A great user interface for smoother and more engaging navigation.",
      "Quick loading times and reliable uptime.",
    ],
    requirements2: [
      "Accommodate a growing number of users effectively.",
      "Create a platform that encourages more students to enroll in tutoring services.",
    ],
    category: "Technology",
    solution:
      "At Ditinus Technology, we executed a redesign of the client’s website, focusing on a clean, modern UI that improves usability and visual appeal. We used intuitive navigation elements, engaging visuals, and streamlined content organization to facilitate easier access to information about courses, tutors, and scheduling.  We optimized the website’s backend to enhance loading speed and reliability, employing continuous integration and deployment practices. This not only improved overall performance but also allowed for quicker updates and maintenance, ensuring the platform could effectively support the educational needs of its users.",
    images: [portfolioimage07, portfolioimage08],
  },

  {
    id: "6",
    slug: "about-synamic-seamless",
    image: MundoChiapasBanner,
    image2: MundoChiapasThumbnail,
    title: "About Dynamic Seamless ",
    description:
      "The company specializes in B2B wholesale fashion, offering a wide range of high-quality clothing and accessories that blend elegance with diverse styles. They focus on providing a seamless shopping experience, ensuring that businesses receive top-tier products quickly and efficiently.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "The client approached us to enhance their OpenCart functionality with the following key requirements:",
    requirements: [
      "An easy-to-use system for managing product listings.",
      "Optimized Cart Functionality.",
      "Streamlined Checkout Process for hassle free transactions. ",
    ],
    requirements2: [
      "Manage inventory with real-time updates.",
      "Buyer accounts and order tracking to track orders, view previous purchases, and manage future transactions.",
    ],
    category: "Technology",
    solution:
      "We improved the client’s OpenCart functionality by redesigning the user interface for an advanced product management system. This made it easier to handle bulk products and pricing. We optimized the cart for flexible transactions and simplified the checkout process with a secure payment gateway. Real-time inventory and order management were added, reducing errors and enhancing operational efficiency. Additionally, these enhancements led to higher customer satisfaction, reduced cart abandonment, and increased overall business growth.",
    images: [portfolioimage07, portfolioimage08],
  },
];
