import Slider from "react-slick";
import { MouseParallax } from "react-just-parallax";
import {
  bottomline,
  bpoicon,
  creativesimg,
  digitalmarketingicon,
  dynamicsolutionsicon,
  expertiseicon,
  friendsimg,
  // htmllogo2,
  Innovatorsimg,
  javascriptlogo2,
  Laravellogo,
  latesttechnologyicon,
  onlinestore,
  phplogo,
  premiumresouresicon,
  prioritizeicon,
  reactlogo2,
  softdevelopmenticon,
  timelydeliveryprojectsicon,
  uiuxicon,
  webdesignicon,
  wordpresslogo2,
} from "src/assets";

const Index = () => {
  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div>
      <main>
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8 col-md-12 col-12 text-center">
                <h1 className="heading-01">Services</h1>
                <p className="p-text18 m-0">
                  We Go Beyond Just Business! Crafting the Digital Magic So Your
                  Business Grows to New Heights.
                </p>
                {/* <p className="heading-05">What we can do&nbsp;for you.</p> */}
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#form-container"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                <h2 className="p-text18">What We Can Do For You</h2>
                <h4 className="heading-05">
                  Diverse Solutions, Detailed Expertise, Tangible Results.
                </h4>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <p className="p-text">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row faq-content-box-row">
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img src={uiuxicon} alt="icon" />
                    <h6 className="heading-08 ms-2">UI/UX</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Are you curious about how great design can help you break
                    into new markets?
                    <br />
                    Our web designing company in India crafts solutions that put
                    your users first. Great design goes far beyond just the
                    aesthetic appeal of your website, it compiles the essential
                    elements of intuitive layouts to seamless interaction. Our
                    UI/UX services in India deliver designs that captivate and
                    delight your users, ultimately driving success for your
                    business.
                  </p>
                </div>
              </div>
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img src={softdevelopmenticon} alt="icon" />
                    <h6 className="heading-08 ms-2">Software Development</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    The best way to enter new markets is to introduce features
                    never known before!
                    <br />
                    And we at the best IT company in India help you enter the
                    market by creating functional, trendy, and user-friendly
                    applications.
                  </p>
                </div>
              </div>
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img src={digitalmarketingicon} alt="icon" />
                    <h6 className="heading-08 ms-2">Digital Marketing</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    To bring your ideas to life, you need to captivate your
                    target audience.
                    <br />
                    What better way to do it than with our top-notch digital
                    marketing services in India? Our expert marketers craft
                    personalized digital solutions keeping your brand’s goals
                    and objectives in mind. With our optimized tactics and
                    expert strategies, your brand is guaranteed to leave a
                    lasting impression and captivate your audience.
                  </p>
                </div>
              </div>
              {/* <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img src={webdesignicon} alt="icon" />
                    <h6 className="heading-08 ms-2">Graphics Design</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    <br />
                    Dolor perferendis ipsa ducimus iusto nihil minima numquam,
                    voluptas quasi labore animi nisi nobis dolore eligendi id
                    odio impedit eos sapiente eius! dolor sit amet consectetur
                    adipisicing elit. Dolor perferendis ipsa ducimus iusto nihil
                    minima numquam, voluptas quasi labore animi nisi nobis
                    dolore eligendi id odio impedit eos sapiente eius!
                  </p>
                </div>
              </div> */}
              <div className="col-12 faq-content-box hover-text">
                <div className="faq-title d-flex justify-content-between">
                  <div className="faq-title-left d-flex align-items-center">
                    <img src={bpoicon} alt="icon" />
                    <h6 className="heading-08 ms-2">KPO/BPO</h6>
                  </div>
                  <div className="faq-title-right d-flex align-items-center">
                    <i className="bi bi-arrow-up-right text-WH d-flex" />
                  </div>
                </div>
                <div className="faq-description ">
                  <p className=" lh-lg">
                    Enhance your business efficiency and outcomes with our
                    tailored BPO and KPO services in India.
                    <br />
                    Our experienced team streamlines processes, reduces costs,
                    and drives success, ensuring tangible results in the long
                    run. Whether you need in-depth market research or
                    data-driven strategies, we customize our services to meet
                    your unique needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whytechnology-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12 text-center">
                <h2 className="heading-06">
                  Why choose Ditinus Technology&nbsp;?
                </h2>
                <p className="text-gray">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src={expertiseicon} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">Expertise</h5>
                  <p className="text-gray text-center">
                    With years of delivering successful projects and a satisfied
                    clientele, Ditinus Technology ranks among the best IT
                    companies in India. We bring a wealth of knowledge and
                    technical know-how to every project, meeting the unique
                    needs of your business.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={dynamicsolutionsicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Dynamic Solutions
                  </h5>
                  <p className="text-gray text-center">
                    The realm of technology keeps changing, bringing with it the
                    need to evolve and adjust to the dynamic needs of the
                    digital landscape. At the best IT company in India, we adapt
                    our strategies to meet the evolving requirements and diverse
                    preferences, ensuring tailored solutions for every client.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src={prioritizeicon} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Prioritize you first
                  </h5>
                  <p className="text-gray text-center">
                    Your satisfaction and success are the top priority of our IT
                    company in India and that is why we are dedicated to
                    understanding your goals and objectives before crafting
                    perfection.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={premiumresouresicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Premium Resources
                  </h5>
                  <p className="text-gray text-center">
                    Our in-house experienced developers, with a track record of
                    serving Fortune companies, offer unparalleled value to your
                    development needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={latesttechnologyicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Latest Technology
                  </h5>
                  <p className="text-gray text-center">
                    For a stunning business presence, you need to lay hands on
                    the best technology to make an impact on your audience. With
                    the best IT company in India, your business experiences
                    transformation with the latest technologies and tools
                    delivering efficient, effective, and scalable solutions that
                    drive business growth.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={timelydeliveryprojectsicon}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Timely Delivery of Projects
                  </h5>
                  <p className="text-gray text-center">
                    We understand the importance of deadlines and are committed
                    to delivering projects on time and within budget. With the
                    best IT company in India, you get nothing but the best for
                    your business!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whytechnology-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12 text-center">
                <h2 className="heading-06">We Go Beyond Just Business!</h2>
                <p className="text-gray">
                  At the best IT company in India, we believe in achieving
                  success beyond simply delivering projects. Our innovative
                  approach, cutting-edge technology, and customized solutions
                  are all that you need to take your digital dreams to new
                  heights.
                </p>
              </div>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src={creativesimg} alt="img" className="mx-auto" />
                  </div>
                  <h5 className="heading-02 text-WH text-center">Creatives</h5>
                  <p className="p-text18 text-center">
                    Partner with innovative minds who bring fresh perspectives
                    to your IT projects.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src={Innovatorsimg} alt="img" className="mx-auto" />
                  </div>
                  <h5 className="heading-02  text-WH text-center">
                    Innovators
                  </h5>
                  <p className="p-text18 text-center">
                    Rely on our inventive approach to drive transformative
                    solutions for your IT needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox bg-transparent shadow-none"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src={friendsimg} alt="img" className="mx-auto" />
                  </div>
                  <h5 className="heading-02  text-WH text-center">Friends</h5>
                  <p className="p-text18 text-center">
                    Experience a supportive partnership where your goals become
                    our shared mission.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="build-awesome-section">
          <div className="container">
            <div className="row position-relative justify-content-between">
              <h4 className="awesome-bg-text">awesome</h4>
              <div className="col-xl-4 col-lg-5 col-md-12 col-12">
                <div className="move-img2">
                  <MouseParallax enableOnTouchDevice isAbsolutelyPositioned>
                    <img src={onlinestore} alt="img" />
                  </MouseParallax>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                <h2 className="heading-06">We build awesome!&nbsp;</h2>
                <h4 className="p-text18 lh-lg">
                  We understand the unique needs of our clients and their users,
                  tailoring our strategies to meet their specific requirements,
                  ensuring excellence every step of the way.
                </h4>
                <ul className="mt-5  text-WH">
                  <li>
                    Trust us for reliable, top-notch solutions that prioritize
                    your satisfaction.
                  </li>
                  <li>
                    Benefit from our seasoned team’s expertise in addressing
                    diverse IT requirements.
                  </li>
                  <li>
                    We tailor our services to match your unique IT needs
                    effectively.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <h2 className="heading-06 lh-sm text-center text-WH">
                  The Things
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mt-5 text-center">
                <p
                  className="p-text fw-medium text-WH "
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings3}>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                {/* <div>
                  <img
                    className="mx-auto"
                    src={htmllogo2}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div> */}
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={wordpresslogo2}
                    alt="WORDPRESS LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1600}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={reactlogo2}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={javascriptlogo2}
                    alt="JAVASCRIPT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={2100}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                {/* <div>
                  <img
                    className="mx-auto"
                    src={htmllogo2}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div> */}
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* <section className="business-mail-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="heading-02">Let's lift up your business!</h2>
                <p className="heading-05">
                  Ready to start creating an amazing business?
                </p>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-8 col-12">
                <form className="row">
                  <div className="col-12">
                    <input
                      type="email"
                      placeholder="E-mail"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="fill-btn outline-btn2 w-100 rounded-pill"
                    >
                      Get A Qoute
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default Index;
