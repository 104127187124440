import Slider from "react-slick";
import ScreenshotsDeck from "../../Components/ScreenDeck";
import {
  bottomline,
  certifiedicon,
  dotsGroup,
  gallary1,
  gallary2,
  gallary3,
  gallary4,
  gallary5,
  htmllogo,
  javascriptlogo2,
  Laravellogo,
  missionicon,
  phplogo,
  reactlogo2,
  visionicon,
  wordpresslogo2,
} from "src/assets";
// import { useEffect, useMemo, useState } from "react";
// import Particles, { initParticlesEngine } from "@tsparticles/react";
// import {
//   type Container,
//   type ISourceOptions,
//   MoveDirection,
//   OutMode,
// } from "@tsparticles/engine";
// // import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// // import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim";
const Index = () => {
  // const [init, setInit] = useState(false);

  // // this should be run only once per application lifetime
  // useEffect(() => {
  //   initParticlesEngine(async (engine) => {
  //     // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //     // starting from v2 you can add only the features you need reducing the bundle size
  //     //await loadAll(engine);
  //     //await loadFull(engine);
  //     await loadSlim(engine);
  //     //await loadBasic(engine);
  //   }).then(() => {
  //     setInit(true);
  //   });
  // }, []);

  // const particlesLoaded = async (container?: Container): Promise<void> => {
  //   console.log(container);
  // };

  // const options: ISourceOptions = useMemo(
  //   () => ({
  //     background: {
  //       color: {
  //         value: "#0d47a1",
  //       },
  //     },
  //     fpsLimit: 120,
  //     interactivity: {
  //       events: {
  //         onClick: {
  //           enable: true,
  //           mode: "push",
  //         },
  //         onHover: {
  //           enable: true,
  //           mode: "repulse",
  //         },
  //       },
  //       modes: {
  //         push: {
  //           quantity: 4,
  //         },
  //         repulse: {
  //           distance: 200,
  //           duration: 0.4,
  //         },
  //       },
  //     },
  //     particles: {
  //       color: {
  //         value: "#ffffff",
  //       },
  //       links: {
  //         color: "#ffffff",
  //         distance: 150,
  //         enable: true,
  //         opacity: 0.5,
  //         width: 1,
  //       },
  //       move: {
  //         direction: MoveDirection.none,
  //         enable: true,
  //         outModes: {
  //           default: OutMode.out,
  //         },
  //         random: false,
  //         speed: 6,
  //         straight: false,
  //       },
  //       number: {
  //         density: {
  //           enable: true,
  //         },
  //         value: 80,
  //       },
  //       opacity: {
  //         value: 0.5,
  //       },
  //       shape: {
  //         type: "circle",
  //       },
  //       size: {
  //         value: { min: 1, max: 5 },
  //       },
  //     },
  //     detectRetina: true,
  //   }),
  //   []
  // );

  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: true,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      {/* {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      )} */}
      <main>
        {/* <div className="aboutPageBg">
          <img src={dotsGroup} alt="img" />
        </div> */}

        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8 col-md-12 col-12 text-center">
                <h1 className="heading-01">About Us</h1>
                <p className="p-text">
                  Listen, we’re not exactly mathematicians over here, but we’re
                  experts at solving the equation of success for our clients!
                </p>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#form-container"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section aboutPageSection-01">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-12">
                <h2 className="heading-06 lh-sm">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    About Us
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-start align-items-center">
                <h4 className="fw-bold heading-08">
                  Innovative IT Solutions in India <br />
                  <span className="fw-normal">
                    Our Story, Our Zeal, Our Promise !
                  </span>
                </h4>
              </div>
              <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-center align-items-center">
                <p className="p-text">
                  Unlock the potential for fast business growth with the best IT
                  company in India. Discover how wework to take your business
                  Forward!
                </p>
              </div>
            </div>
            <div className="row justify-content-between mt-4 about-img-content">
              <div className="col-12 col-xl-5 col-lg-5 col-md-7">
                <ScreenshotsDeck />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <h5 className="heading-05 text-denim">
                  Turning awesome Ideas Into Reality!
                </h5>
                <h4 className="heading-06 fw-bold mb-4">
                  Stay Ahead in The Digital Realm{" "}
                  <span className="fw-normal">Turn Ideas Into Reality.</span>
                </h4>
                <p className="text-WH p-text lh-lg">
                  Staying ahead of the competition in today’s digital era is
                  crucial for propelling fast success. But with so much
                  competition online, how can you ensure your business stands
                  out and becomes the talk of the town?
                  <br /> Shall we cut to the chase? we’re not exactly
                  mathematicians over here, but we’re experts at solving the
                  equation of success for our clients! Break down the equation
                  of your business growth with the best IT company in India!
                </p>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 mx-auto text-center my-xl-5 my-lg-5 my-md-3 my-2 ">
                <p className="text-WH">
                  Staying ahead of the competition in today’s digital era is
                  crucial for propelling fast success. But with so much
                  competition online, how can you ensure your business stands
                  out and becomes the talk of the town? Shall we cut to the
                  chase? we’re not exactly mathematicians over here, but we’re
                  experts at solving the equation of success for our clients!
                  Break down the equation of your business growth with the best
                  IT company in India!
                </p>
              </div>
            </div>
            <div className="row mt-4 design-text">
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                <div
                  className="img-icon text-center"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={visionicon} alt="icon" />
                </div>
                <h5 className="heading-08 fw-bold text-WH">Vision</h5>
                <p className="text-WH p-text">
                  We at Ditinus Technology aspire to be the leading brand in IT
                  solutions trusted by our clients to deliver compelling and
                  value-driven results.
                </p>
              </div>
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <div
                  className="img-icon text-center"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={missionicon} alt="icon" />
                </div>
                <h5 className="heading-08 fw-bold text-WH">Mission</h5>
                <p className="text-WH p-text">
                  Our mission as an IT company is to provide innovative and
                  high-quality solutions that provide value to our clients and
                  help them succeed.
                </p>
              </div>
              <div
                className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <div
                  className="img-icon text-center"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={certifiedicon} alt="icon" />
                </div>
                <h5 className="heading-08 fw-bold text-WH">Certified Team</h5>
                <p className="text-WH">
                  Our team of certified professionals works round the clock to
                  bring you the finest solutions that are time-saving and
                  result-oriented.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="gallery-section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="heading-02">Take a Look</h2>
                <p className="p-text">Snapshots of Our Story</p>
              </div>
            </div>
            {/* <div className="row gallery-grid-box">
              <div className="col-xl-4 col-lg-4 col-md-4 left-grid  ">
                <div className="col-12 ">
                  <img  src={gallary1} />
                </div>
                <div className="col-12 ">
                  <img  src={gallary2} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 center-grid ">
                <div className="col-12 ">
                  <img  src={gallary3} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 right-grid">
                <div className="col-12 ">
                  <img  src={gallary4} />
                </div>
                <div className="col-12 ">
                  <img  src={gallary5} />
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0 h-25">
                <img
                  src={gallary1}
                  style={{ height: "257px" }}
                  className="w-100  shadow-1-strong  mb-4"
                  alt="Boat on Calm Water"
                />
                <img
                  src={gallary2}
                  style={{ height: "384px" }}
                  className="w-100    shadow-1-strong mb-4"
                  alt="Wintry Mountain Landscape"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0 h-25">
                <img
                  src={gallary3}
                  style={{ height: "666px" }}
                  className="w-100 shadow-1-strong  mb-4"
                  alt="Mountains in the Clouds"
                />
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0 h-25">
                <img
                  src={gallary4}
                  style={{ height: "257px" }}
                  className="w-100 shadow-1-strong  mb-4"
                  alt="Waves at Sea"
                />
                <img
                  src={gallary5}
                  style={{ height: "384px" }}
                  className="w-100 shadow-1-strong  mb-4"
                  alt="Yosemite National Park"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="heading-02 lh-sm text-center text-WH">
                  The Things
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mt-5 text-center">
                <p
                  className="p-text fw-medium text-WH "
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings3}>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={wordpresslogo2}
                    alt="WORDPRESS LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1600}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={reactlogo2}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={javascriptlogo2}
                    alt="JAVASCRIPT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={2100}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* <section className="business-mail-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="heading-02">Let's lift up your business!</h2>
                <p className="heading-05">
                  Ready to start creating an amazing business?
                </p>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-8 col-12">
                <form className="row">
                  <div className="col-12">
                    <input
                      type="email"
                      placeholder="E-mail"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="fill-btn outline-btn2 w-100 rounded-pill"
                    >
                      Get A Qoute
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default Index;
